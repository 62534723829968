import classNames from "classnames"
import { twMerge } from "tailwind-merge"

const Link = ({ to, children, className, target = true }) => {
  const classes = twMerge(
    classNames("text-silver tracking-wide hover:text-silver-600", className)
  )

  let targetProps
  if (target) {
    targetProps = { rel: "noreferrer", target: "_blank" }
  }

  return (
    <a href={to} className={classes} {...targetProps}>
      {children}
    </a>
  )
}

export default Link
