import React from "react"
import ReactDOM from "react-dom/client"
import "./index.css"
import App from "./App"

// Find element with id root
const el = document.getElementById("root")

// Tell React to take control of the element
const root = ReactDOM.createRoot(el)

// Tell React to show the component on the screen
root.render(<App />)
