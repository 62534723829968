import "./app.css"
import Link from "./components/Link"
import photo from "./images/photo.jpg"
import awsDev from "./images/aws-dev.png"
import awsPract from "./images/aws-pract.png"
import awsAI from "./images/aws-ai.png"
import comps from "./images/comps.png"
import webTemplate from "./images/web-template.png"
import scrumCert from "./images/scrum-cert.webp"
import staticWeb from "./images/aws-static-website.png"

function App() {
  const url = {
    gitHubUrl: "https://github.com/domesoldo",
    linkedInUrl: "https://www.linkedin.com/in/domenicosoldo/",
    instagramUrl: "https://instagram.com/dom3sol",
  }

  return (
    <div className="container m-auto px-6 md:px-10">
      <header className="flex md:flex-row flex-col justify-between md:mt-24 mt-8">
        <div>
          <h1 className="text-5xl md:text-xxxl">Domenico Soldo</h1>
          <h2 className="text-3xl md:text-xxl text-silver">Web Developer</h2>
        </div>

        <ul className="flex flex-col justify-evenly items-end mt-12 md:mt-0">
          <li className="nav-item">
            <Link to={url.gitHubUrl} className="tracking-widest text-lg">
              GitHub
            </Link>
          </li>
          <li className="nav-item">
            <Link to={url.linkedInUrl} className="tracking-widest text-lg">
              LinkedIn
            </Link>
          </li>
          <li className="nav-item">
            <Link to={url.instagramUrl} className="tracking-widest text-lg">
              Instagram
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="mailto:soldodomenico@gmail.com"
              className="tracking-widest text-lg">
              Email
            </Link>
          </li>
        </ul>
      </header>

      <main className="mb-28">
        <section id="about-me" className="mt-24 md:mt-64">
          <h3 className="my-12">
            {"\u007B"} About Me {"\u007D"}
          </h3>
          <div className="grid lg:grid-cols-2 grid-cols-1 gap-24 items-center">
            <p className="md:text-xl text-base leading-tight tracking-tight text-pretty whitespace-pre-line">
              {`
              Dom has over 15+ years of experience in digital consulting as a full-stack developer across a broad range of software development frameworks and platforms. 
              
              Dom's core expertise includes Web Frontend, and Cloud Solutions such as React and AWS Cloud Services.

              Passionate about Web Development, Design Accessibility and Cloud Services, Dom portfolio showcases diverse practical solutions delivered on AWS Cloud Services.`}
            </p>
            <img
              src={photo}
              alt="Domenico Soldo"
              className="about-img w-full object-cover object-top"
            />
          </div>
        </section>

        <section id="works" className="mt-24 md:mt-64">
          <h3 className="my-12">
            {"\u007B"} Selected Work {"\u007D"}
          </h3>
          <div className="grid grid-cols-6 gap-4">
            {/* UI Components Library */}
            <div className="rounded-xl border-2 border-slate-400/10 bg-neutral-100 md:p-12 p-6 dark:bg-neutral-900 row-span-2 md:col-span-4 col-span-6">
              <img
                src={comps}
                alt="Components Library website"
                className="w-full object-cover"
              />
            </div>
            <div className="rounded-xl border-2 border-slate-400/10 bg-neutral-100 p-4 dark:bg-neutral-900 row-span-1 md:col-span-2 md:block col-span-3 hidden">
              <div className="h-full flex justify-center items-center">
                <Link to="https://comp.domsoldo.com/" target={false}>
                  <p className="text-2xl text-center">UI Components Library</p>
                </Link>
              </div>
            </div>
            <div className="rounded-xl border-2 border-slate-400/10 bg-neutral-100 p-4 dark:bg-neutral-900 row-span-1 md:col-span-2 col-span-6">
              <div className="h-full flex flex-col justify-center">
                <Link to="https://comp.domsoldo.com/" target={false}>
                  <p className="text-2xl p-4 block md:hidden">
                    UI Components Library
                  </p>
                </Link>
              </div>
            </div>

            {/* Website Template */}
            <div className="rounded-xl border-2 border-slate-400/10 bg-neutral-100 p-4 dark:bg-neutral-900 row-span-1 md:col-span-2 col-span-6 md:block hidden">
              <div className="h-full flex flex-col justify-center">
                <Link to="https://ft.domsoldo.com/" target={false}>
                  <p className="text-2xl mb-2 text-center p-4 block">
                    Website Template
                  </p>
                </Link>
              </div>
            </div>
            <div className="rounded-xl border-2 border-slate-400/10 bg-neutral-100 md:p-12 p-6 dark:bg-neutral-900 row-span-2 md:col-span-4 col-span-6">
              <img
                src={webTemplate}
                alt="A Collection of reusable UI React Components"
                className="w-full object-cover"
              />
            </div>
            <div className="rounded-xl border-2 border-slate-400/10 bg-neutral-100 p-4 dark:bg-neutral-900 row-span-1 md:col-span-2 col-span-3 col-span-6">
              <div className="h-full flex justify-center items-center">
                <Link to="https://ft.domsoldo.com/" target={false}>
                  <p className="text-2xl mb-2 text-center md:hidden">
                    Website Template
                  </p>
                </Link>
              </div>
            </div>

            {/* SSR Box */}
            <div className="rounded-xl border-2 border-slate-400/10 bg-neutral-100 p-4 dark:bg-neutral-900 row-span-1 col-span-6 md:col-span-2">
              <Link
                to="https://stores-products.vercel.app/"
                target={false}
                className="text-black">
                <p className="text-2xl mb-2">SSR & Next.JS 14</p>
                <p className="text-silver-600">
                  A web application for managing stores & products that connects
                  with APIs through Next.JS 14 with Server Side Render
                </p>
              </Link>
              <p className="nav-item text-right mt-6">
                <Link
                  to="https://github.com/domesoldo/stores-products/tree/main/with-next-js"
                  className="tracking-wide pb-2">
                  {`[ GitHub ]`}
                </Link>
              </p>
            </div>

            {/* Redux Box */}
            <div className="rounded-xl border-2 border-slate-400/10 bg-neutral-100 p-4 dark:bg-neutral-900 row-span-1 col-span-6 md:col-span-2">
              <Link
                to="https://stores-products-111k.vercel.app/"
                target={false}
                className="text-black">
                <p className="text-2xl mb-2">Redux-Toolkit</p>
                <p className="text-silver-600">
                  A web application for managing stores & products that connects
                  with APIs through Redux-Toolkit
                </p>
              </Link>
              <p className="nav-item text-right mt-6">
                <Link
                  to="https://github.com/domesoldo/stores-products/tree/main/with-redux-toolkit"
                  className="tracking-wide pb-2">
                  {`[ GitHub ]`}
                </Link>
              </p>
            </div>

            {/* Provider Pattern Box */}
            <div className="rounded-xl flex flex-col justify-between border-2 border-slate-400/10 bg-neutral-100 p-4 dark:bg-neutral-900 row-span-1 col-span-6 md:col-span-2">
              <div>
                <p className="text-2xl mb-2">Provider Pattern</p>
                <p className="text-silver-600">
                  An implementation in React of the Theme Provider Pattern
                </p>
              </div>

              <p className="nav-item text-right mt-6">
                <Link
                  to="https://github.com/domesoldo/provider-pattern-web-demo"
                  className="tracking-wide pb-2">
                  {`[ GitHub ]`}
                </Link>
              </p>
            </div>

            {/* AWS Static Website */}
            <div className="rounded-xl border-2 border-slate-400/10 bg-neutral-100 p-1 dark:bg-neutral-900 row-span-2 md:col-span-4 col-span-6">
              <img
                src={staticWeb}
                alt="Static Website Architecture"
                className="w-full object-cover"
              />
            </div>
            <div className="rounded-xl border-2 border-slate-400/10 bg-neutral-100 p-4 dark:bg-neutral-900 row-span-2 md:col-span-2 col-span-6">
              <div className="h-full flex flex-col justify-center">
                <p className="text-2xl text-center mb-2">
                  Static Website Architecture
                </p>
                <p className="text-silver-600 text-pretty whitespace-pre-line text-center">
                  {`The Continuous Integration/Continuous Deployment (CI/CD) process for this portfolio site is constructed using AWS Pipeline to facilitate deployment to the AWS S3 & CloudFront.`}
                </p>
              </div>
            </div>
          </div>
        </section>

        <section id="experiences" className="mt-24 md:mt-64">
          <h3 className="my-12">
            {"\u007B"} Experiences {"\u007D"}
          </h3>

          <div className="grid grid-flow-row md:grid-cols-2 gap-24">
            <h4 className="text-2xl font-bold">
              Current & previous
              <br />
              experience
            </h4>
            <div className="flex flex-col">
              <div className="border-b-silver">
                <p className="experience-title text-xxl">Deloitte</p>
                <div className="flex flex-row justify-between items-center">
                  <p className="experience">Senior Web Developer</p>
                  <p className="experience text-silver">
                    Melbourne, 2024 - today
                  </p>
                </div>
              </div>
              <hr className="border-silver my-6" />

              <div className="border-b-silver">
                <p className="experience-title text-xxl">PwC Australia</p>
                <div className="flex flex-row justify-between items-center">
                  <p className="experience">
                    Senior Web Developer
                    <br />
                    Cloud & Technology Consulting Specialist
                  </p>
                  <p className="experience text-silver">
                    Melbourne 2017 - 2024
                  </p>
                </div>
              </div>
              <hr className="border-silver my-6" />

              <div className="border-b-silver">
                <p className="experience-title text-xxl">TechMobile</p>
                <div className="flex flex-row justify-between items-center">
                  <p className="experience">Web and Mobile Developer</p>
                  <p className="experience text-silver">Milan 2011 - 2013</p>
                </div>
              </div>
              <hr className="border-silver my-6" />
              <div className="border-b-silver">
                <p className="experience-title text-xxl">Alten Italia</p>
                <div className="flex flex-row justify-between items-center">
                  <p className="experience">Java Developer</p>
                  <p className="experience text-silver">Milan 2008 - 2011</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="certifications" className="mt-24 md:mt-64">
          <h3 className="my-12">
            {"\u007B"} Certifications {"\u007D"}
          </h3>
          <div className="flex flex-row flex-wrap">
            <div className="mr-6">
              <a
                href="https://www.credly.com/badges/03a9b07e-966c-4a33-85f6-fb73678a8ada/public_url"
                target="_blank"
                rel="noreferrer">
                <img
                  src={awsAI}
                  alt="AWS Certified AI Practitioner Early Adopter"
                  width={150}
                  height={150}
                />
              </a>
            </div>

            <div className="mr-6">
              <a
                href="https://www.credly.com/badges/f2f1e62f-0401-4afc-9197-0888ae7242d8/public_url"
                target="_blank"
                rel="noreferrer">
                <img
                  src={awsDev}
                  alt="AWS Certified Developer - Associate"
                  width={150}
                  height={150}
                />
              </a>
            </div>
            <div className="mr-6">
              <a
                href="https://www.credly.com/badges/9a19c152-1610-45ef-ad14-d1ffe9190d5f/public_url"
                target="_blank"
                rel="noreferrer">
                <img
                  src={awsPract}
                  alt="AWS Certified Cloud Practitioner"
                  width={150}
                  height={150}
                />
              </a>
            </div>

            <div>
              <a href="" rel="noreferrer">
                <img
                  src={scrumCert}
                  alt="Certified ScrumMaster"
                  width={150}
                  height={150}
                />
              </a>

              <br />
            </div>
          </div>
        </section>

        <section id="contact" className="mt-24 md:mt-64">
          <h3 className="my-12">
            {"\u007B"} Available for new projects {"\u007D"}
          </h3>
          <div className="contact-me h-96 rounded-3xl flex justify-center items-center">
            <div className="max-w-screen-sm flex flex-col items-center justify-evenly h-full">
              <p className="contact-title">Let’s work together</p>
              <div className="contact-btn flex justify-center items-center">
                <a
                  href="mailto:soldodomenico@gmail.com"
                  target="_blank"
                  rel="noreferrer"
                  className="py-2.5 px-4 border font-bold rounded-full hover:text-silver-600 hover:py-3 hover:px-5">
                  Contact me
                </a>
              </div>
            </div>
          </div>
        </section>
      </main>

      <footer className="mb-12">
        <hr className="border-silver w-full my-6" />
        <ul className="flex flex-row justify-between">
          <li className="nav-item">
            <Link to={url.linkedInUrl}>LinkedIn</Link>
          </li>
          <li className="nav-item">
            <Link to={url.gitHubUrl}>GitHub</Link>
          </li>
          <li className="nav-item">
            <Link to={url.instagramUrl}>Instagram</Link>
          </li>
          <li className="nav-item">
            <Link to="https://www.hackerrank.com/profile/soldodomenico">
              HackerRank
            </Link>
          </li>
        </ul>
      </footer>
    </div>
  )
}

export default App
